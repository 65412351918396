import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import LetsTalk from '../components/LetsTalk'

import AtlantaSkyline from '../components/images/AtlantaSkyline'

const AboutPage = () => (
  <Layout>
    <Seo title="About | Clove" />

    <div className="relative mt-8">
      <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
        <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
          <h2 className="heading-primary">About Clove</h2>
          <p className="mt-8 text-primary">
            Clove is all about customer love. Our customer experience platform delivers a cohesive
            experience through every step of the customer relationship.
          </p>
        </div>
      </div>

      <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
        <AtlantaSkyline />
      </div>
    </div>

    <div className={"relative bg-gray-50 py-20"}>
      <div className={"mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl"}>
        <h2 className="heading-secondary">Our Values</h2>

        <p className="mt-8 text-primary">
          We believe that a company should put shared values at the center of
          all decisions and interactions. Values-focused teams create better
          products that better serve customers. These values are who we are
          and who we intend to be.
        </p>

        <div className="mt-12 grid md:grid-cols-3 gap-8">
          <div>
            <h3 className="font-semibold text-gray-900 text-xl">Maintain Humility</h3>
            <p className="mt-2 text-secondary">
              We are products of our strengths and weaknesses. We openly
              acknowledge mistakes to learn and improve. We depend on our
              teammates to achieve success.
            </p>
          </div>

          <div>
            <h3 className="font-semibold text-gray-900 text-xl">Seek Truth</h3>
            <p className="mt-2 text-secondary">
              We value truth more than being right. We continually test our
              assumptions and adjust to new information.
            </p>
          </div>

          <div>
            <h3 className="font-semibold text-gray-900 text-xl">Sacrifice for Peers in Need</h3>
            <p className="mt-2 text-secondary">
              We approach conflict assuming the best intentions. We put our
              teammates before ourselves to better serve our shared goals.
            </p>
          </div>
        </div>
      </div>
    </div>

    <LetsTalk />
  </Layout>
)

export default AboutPage
